import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor(private api: ApiService) {}
  getMedicalDevices(): any {
    return this.api.post("api/medicalDevice",{} );
  }
  getCategories(): any {
    return this.api.post("api/deviceCategory",{} );
  }
  getSubCategories(): any {
    return this.api.post("api/deviceSubCategory",{} );
  }
  getProductDetails(id):any{
  
    return this.api.get("api/medicaldevice/"+id );
  }
  
  
}
