import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/core/services/api/api.service';
import { PagesService } from 'src/app/core/services/pages/pages.service';

@Component({
  selector: 'app-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.css']
})
export class PartnerComponent implements OnInit {
  partners:any=[];
  constructor(private pageService:PagesService,private apiService:ApiService) { }

  ngOnInit() {
    this.pageService.getPartners().subscribe((resp: any) => {
      this.partners = resp.data.data;      
    }, (err: any) => {
      console.log(err);
    });
  }

}
