import { Injectable } from "@angular/core";
import { ApiService } from "../api/api.service";

@Injectable({
  providedIn: "root",
})
export class PagesService {
  constructor(private api: ApiService) {}
  getBanner(): any {
    return this.api.post("api/banner",{} );
  }
  applyJob(data):any{
    return this.api.post("api/application/create",data );
  }
  getJobs():any{
    return this.api.post("api/job",{} );
  }
  getPartners():any{
    return this.api.post("api/partner",{} );
  }
  getTeam():any{
    return this.api.post("api/team",{} );
  }
  getProjects():any{
    return this.api.post("api/project",{} );
  }
  getCms(slug):any{
    return this.api.get("api/cms/"+slug );
  }
  
  getNews():any{
    let data={
      newsType:1
    }
    return this.api.post("api/news",data);
  }
  getevents():any{
    let data={
      newsType:2
    }
    return this.api.post("api/event",data );
  }
  getNewsDetails(id):any{
  
    return this.api.get("api/news/"+id );
  }
  getProjectDetails(id):any{
  
    return this.api.get("api/project/"+id );
  }
  getCountries():any{
  
    return this.api.get("api/country");
  }
  getDept():any{
  
    return this.api.post("api/servicedepartment",{});
  }
  postEnquiry(data):any{
  
    return this.api.post("api/enquiry/create/",data);
  }
  getContacts(data):any{
  
    return this.api.post("api/contact",data);
  }
  getJobCategory():any{
  
    return this.api.post("api/jobcategory",{});
  }
  uploadFile(file){
    return this.api.postFile("api/upload",file);
  }



}
