import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from './core/components/not-found/not-found.component';
import { SignupComponent } from './modules/main/components/user/signup/signup.component';
const routes:Routes=[
  {path: '', loadChildren: () => import('./modules/main/main.module').then(m => m.MainModule)},
  {path: '**' , component : NotFoundComponent},

];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ 
    scrollPositionRestoration: 'enabled', 
  // anchorScrolling: 'enabled'
 })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
