import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/core/services/api/api.service';
import { PagesService } from 'src/app/core/services/pages/pages.service';

@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.css']
})
export class ProjectDetailsComponent implements OnInit {
  id:any;
  project:any;

  constructor(private _activatedRoute:ActivatedRoute, public sanitizer: DomSanitizer,private pageService:PagesService, private apiService:ApiService) { }

  ngOnInit() {
    this._activatedRoute.params.subscribe((params) => {
      console.log(params);
      this.id = params.id;
      this.pageService.getProjectDetails(this.id).subscribe((resp: any) => {
        this.project = resp.data;      
      }, (err: any) => {
        console.log(err);
      });
    });
  }

}
