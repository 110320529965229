import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/core/services/api/api.service';
import { PagesService } from 'src/app/core/services/pages/pages.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {
  news:any=[];
  constructor(private pageService:PagesService, private apiService:ApiService) { }

  ngOnInit() {
    this.pageService.getNews().subscribe((resp: any) => {
      this.news = resp.data.data; 
    }, (err: any) => {
      console.log(err);
    })
  }

}
