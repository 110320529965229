import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PagesService } from 'src/app/core/services/pages/pages.service';

@Component({
  selector: 'app-gmcprivacy',
  templateUrl: './gmcprivacy.component.html',
  styleUrls: ['./gmcprivacy.component.css']
})
export class GmcprivacyComponent implements OnInit {
  message;
  ngOnInit() {
    this.pageService.getCms("gmc-privacy").subscribe(
      (resp: any) => {
        this.message = resp.data.content;
        console.log(this.message)
      },
      (err: any) => {
        console.log(err);
      }
    );
  }
  constructor(
    private pageService: PagesService,
    private router: Router
  ) {





}}
