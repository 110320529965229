import { Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/core/services/api/api.service';
import { PagesService } from 'src/app/core/services/pages/pages.service';
import { PopupComponent } from '../popup/popup.component';

declare var bootbox;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @ViewChild(PopupComponent) child:PopupComponent;

cms;
  constructor(private router:Router, public sanitizer: DomSanitizer, private pageService:PagesService, public apiService:ApiService) { }

  ngOnInit() {
    this.pageService.getCms('settings').subscribe((resp: any) => {
      this.cms = resp.data; 
      console.log("cms",this.cms)
    }, (err: any) => {
      console.log(err);
    })
  }
  openPopup(){
    // console.log("modal",this.child.showModal1)
    this.child.show('open')
  }
  

}
