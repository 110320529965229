import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth/auth.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
serverErrorMessages:string;
  constructor(private authService :AuthService) { 
    this.authService.getUser().subscribe(res=>{
      console.log("users",res)
    })
    this.authService.login({email:"a@b.com",
  password:"password"}).subscribe(res=>{
    console.log("ressss",res)
    this.authService.setToken(res['token']);
  },err=>{
    this.serverErrorMessages=err.error.message
  })
  }

  ngOnInit() {
  }

}
