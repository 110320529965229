import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MATERIAL_SANITY_CHECKS } from '@angular/material';
import { CoreModule } from './core/core.module';
import { MainModule } from './modules/main/main.module';
@NgModule({
  declarations: [
    AppComponent,

  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CoreModule,
    MainModule

  ],
  providers: [  {
    provide: MATERIAL_SANITY_CHECKS,
    useValue: false
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }
