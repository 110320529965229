import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.css']
})
export class PopupComponent implements OnInit {

  @Output() childEvent = new EventEmitter();

  unsubcribe: any;
  constructor() {
  }

  ngDistroy() {
    this.unsubcribe();
  }
 
  showModal1: boolean=false;
  ngOnInit(): void {
    console.log("") 
  }

  show(event) {
    console.log('data');
    this.showModal1 = true;

    // this.course_id =id;
  }
  hide() {
    this.showModal1 = false;
  
  }

}
