import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PagesService } from 'src/app/core/services/pages/pages.service';
declare var bootbox:any;
@Component({
  selector: 'app-career-details',
  templateUrl: './career-details.component.html',
  styleUrls: ['./career-details.component.css']
})
export class CareerDetailsComponent implements OnInit {
  page="personal";
  grade='';
  gpa='';
  gpa_error =false;
  present=false;
  chronic=0;
  work=0;
  url:any='';
  countries:any=[];
  data={
    firstName:'',
    lastName:'',
    gender:'Male',
    maritalStatus:'Single',
    nationality:'',
    country:'',
    placeOfBirth:'',
    dob:'',
    mobileNo:'',
    phoneNo:'',
    email:'',
    nameOfRelative:'',
    referenceOfEmployees:'',
    chronicDisease:'',
    permanentAddress: {
      streetName: '',
      country: '',
      city: '',
      po: '',
      pinCode: '',
  },
  presentAddress: {
      streetName: '',
      country: '',
      city: '',
      po: '',
      pinCode: '',
  },
  educationHistory: [
     
  ],
  employmentHistory: [
   
  ],
  expectedSalary: '',
  joinDate: '',
  refferedBy: '',
  skill: {
      englishProficiency: '',
      excel: '',
      word: '',
      powerPoint: '',
      skillDescriptions: '',
      hobby: '',
  },
  careerPreference: '',
  firstPriority: '',
  secondPriority: '',
  cv: '',
}
educationHistory=   {
      degree: 'Diploma',
      institute: '',
      country: '',
      graduationStatus: 'Graduated',
      graduationDate: '',
      fieldOfStudy: '',
      major: '',
      gpa:{
        four: '',
        five:'',
      },
      percentage: '',
  }
  employmentHistory=
    {
        from: '',
        to:'',
        isCompleted: false,
        nameAndLocation: '',
        jobTitle: '',
        monthlyIncome: '',
        relievingReason: '',
    }

  
  Job;
  constructor(private pageService:PagesService, private _activatedRoute:ActivatedRoute, private router:Router) { }

  ngOnInit() {
    this._activatedRoute.params.subscribe((params) => {
      console.log(params);
      this.Job = params.id;
      this.data.presentAddress=this.data.permanentAddress;
      this.pageService.getCountries().subscribe(
        (resp: any) => {
          this.countries = resp.data.data;
        },
        (err: any) => {
          console.log(err);
        }
      );
    });
  }
  onSubmit(form:NgForm){
    console.log("submit------>",form.value,form.valid);
    if(form.valid)
    this.page='education';
  }
  checkWork(event){
    event==1? this.work =1:this.work=0;
    this.data.employmentHistory =[];


  }
  checkPresent(event){
    console.log("presetn",event.target.checked)
    if(!event.target.checked){
      this.data.presentAddress={
        streetName: '',
        country: '',
        city: '',
        po: '',
        pinCode: ''}
     
    }else   
    this.data.presentAddress = this.data.permanentAddress;
    console.log("data----",this.data)
  
  }
  addEducation(form:NgForm){
    console.log("edu submit------>",form.value,this.educationHistory);
    if(form.valid && (this.educationHistory.gpa.four || this.educationHistory.gpa.five || this.educationHistory.percentage )){
      if((parseInt(this.educationHistory.gpa.four)<=4 && parseInt(this.educationHistory.gpa.four)>0)  || 
      (parseInt(this.educationHistory.gpa.five)<=5 && parseInt(this.educationHistory.gpa.five)>0) || 
      (parseInt(this.educationHistory.percentage)<=100 && parseInt(this.educationHistory.percentage)>0)){
        let educationHistory ={
          degree:form.value.degree,
          institute:form.value.institute,
          country:form.value.eduCountry,
          graduationStatus:form.value.status,
          graduationDate: form.value.graduationDate,
          fieldOfStudy: form.value.fieldOfStudy,
          major: form.value.major,
        }
        if(this.educationHistory.gpa.five){
          educationHistory['gradingSystem']='GPA-(out of 5)';
          educationHistory['score'] =this.educationHistory.gpa.five;
        }
        if(this.educationHistory.gpa.four){
          educationHistory['gradingSystem']='GPA-(out of 4)';
          educationHistory['score'] =this.educationHistory.gpa.four;
        }
        if(this.educationHistory.percentage){
          educationHistory['gradingSystem']='percentage';
          educationHistory['score'] =this.educationHistory.percentage;
        }    
      
        this.data.educationHistory.push(educationHistory)
        this.gpa_error =false;

        form.resetForm();
      }else{
        this.gpa_error =true;
      }
     

    }
    console.log("education",this.data)
  }
  check(){
    this.gpa_error =false;
  }
  onSubmitEducation(){
    console.log("edu data",this.educationHistory,this.data) 
    this.data.educationHistory.length && (this.page='employment');

  }
  selectGrade(event){
    this.grade=event;
    if(this.grade=='percentage') {
      this.gpa='';
      this.educationHistory.gpa.five='';
      this.educationHistory.gpa.four=''
    } else this.educationHistory.percentage ='';  
    
    console.log("edu data",this.educationHistory,this.data) 
  }
  deleteEdu(education){
    this.data.educationHistory.splice(education,1);
  }
  deleteExp(employment){
    this.data.employmentHistory.splice(employment,1);
  }
  selectGpa=(event)=> this.gpa=event;
  chkDisease(value){
    this.chronic=value;
    console.log("value",this.chronic)
    if(this.chronic==0){
      console.log("enter")
      this.data.chronicDisease=''
    }
  }
  addExp(form:NgForm){
    console.log("work submit------>",form.value,this.employmentHistory);
    let employmentHistory =form.value;
    employmentHistory['isCompleted']=this.employmentHistory.isCompleted;
    if(form.valid){
      this.data.employmentHistory.push(employmentHistory);
      form.resetForm();
      this.employmentHistory.isCompleted=false;

    }
    console.log("employ",this.data)
  }
  onSubmitWork(form){
    form.valid && (this.page='skills')

  }
  onSubmitSkills(form){
    form.valid && (this.page='preferences')
  }
  onSelect(event){
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0]; 
      console.log("file",file) 
      this.pageService.uploadFile(file).subscribe(
        (resp: any) => {
          this.url = resp.data.path.replace("public","");
          console.log("url",this.url)
        },
        (err: any) => {
          console.log(err);
        }
      ); 
   
      // const reader = new FileReader();
     
      // reader.readAsDataURL(file);
    }
  }
  onSubmitFinal(form){
    this.data['Job']=this.Job;
    this.data['cv'] = this.url;
    if(this.url && form.valid){
      this.pageService.applyJob(this.data).subscribe((resp: any) => {
        console.log("data",resp)
        if(resp.success) {
          var dialog = bootbox.dialog({
            message: 'Job applied successfully!!',
            closeButton: true
          });
          this.router.navigateByUrl('/careers')
        }
    
      }, (err: any) => {
        console.log(err);
      });
    }
  
  }
  checkIsCompleted(event){
    console.log("value", event.target.checked);
    this.employmentHistory.isCompleted = event.target.checked;
    if(this.employmentHistory.isCompleted)
    this.employmentHistory.to = new Date().toISOString().split('T')[0];
    else this.employmentHistory.to ='';

  }

}
