import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ApiService } from 'src/app/core/services/api/api.service';
import { PagesService } from 'src/app/core/services/pages/pages.service';

@Component({
  selector: 'app-service-departments',
  templateUrl: './service-departments.component.html',
  styleUrls: ['./service-departments.component.css']
})
export class ServiceDepartmentsComponent implements OnInit {
  service_department:any;
  dept_details:any;

  constructor(private pageService:PagesService, private apiService:ApiService,public sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.pageService.getCms("service-dept").subscribe(
      (resp: any) => {
        this.service_department = resp.data;
      },
      (err: any) => {
        console.log(err);
      }
    );
    this.pageService.getDept().subscribe(
      (resp: any) => {
        this.dept_details = resp.data.data;
      },
      (err: any) => {
        console.log(err);
      }
    );
  }
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    margin:0,
    dots: true,
    autoWidth: true,
    navSpeed: 700,
    navText: [
      '<i class="fa fa-angle-left" aria-hidden="true"></i>',
      '<i class="fa fa-angle-right" aria-hidden="true"></i>',
    ],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 3,
      },
      740: {
        items: 3,
        loop:true

      },
      940: {
        items: 3  ,
        // items: this.couple_plans.length<3?this.couple_plans.length:3,
        loop:true
      },
    },
    nav: false,
  };

}
