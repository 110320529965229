import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { forEach } from '@angular/router/src/utils/collection';
import * as AOS from 'aos';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'gulf-medicals';
  showHeader = false;
  showNav = false;
  showFooter = false;
  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  ngOnInit(){
    AOS.init();
    
  }
}
