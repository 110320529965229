import { Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ApiService } from 'src/app/core/services/api/api.service';
import { PagesService } from 'src/app/core/services/pages/pages.service';
import { DOCUMENT, ViewportScroller } from '@angular/common';
@Component({
  selector: 'app-strategic',
  templateUrl: './strategic.component.html',
  styleUrls: ['./strategic.component.css']
})
export class StrategicComponent implements OnInit {
  vision;
  mission;
  value;

  constructor(private scroller: ViewportScroller,private pageService:PagesService,private apiService:ApiService,public sanitizer: DomSanitizer) { }
  // ngAfterViewInit(): void{
  //   // this.scroller.scrollToAnchor("wevalue");
  //   document.querySelector('#wevalue').scrollIntoView({ behavior: 'smooth', block: 'end' });

  // }
  ngOnInit() {
    // document.querySelector('#wevalue').scrollIntoView({ behavior: 'smooth', block: 'end' });
    // const element = this.document.getElementById('wevalue'); 
    // if (element)  { element.scrollIntoView({ behavior: 'smooth', block: 'start' });}

    this.pageService.getCms("our-vision").subscribe(
      (resp: any) => {
        this.vision = resp.data;
        console.log("vision",this.vision)
      },
      (err: any) => {
        console.log(err);
      }
    );
    this.pageService.getCms("our-mission").subscribe(
      (resp: any) => {
        this.mission = resp.data;
      },
      (err: any) => {
        console.log(err);
      }
    );
    this.pageService.getCms("we-value").subscribe(
      (resp: any) => {
        this.value = resp.data;
      },
      (err: any) => {
        console.log(err);
      }
    );
  }

}
