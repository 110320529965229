import { Component, NgZone, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { PagesService } from 'src/app/core/services/pages/pages.service';
import * as _ from "lodash";
import { Loader } from '@googlemaps/js-api-loader';

declare var bootbox;
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
type:any ='offices'
contacts:any=[];
offices:any=[];
bankers:any=[];
all_offices:any=[];
all_bankers:any=[];
  data={
    firstName:'',
    lastName:'',
    email:'',
    phone:'',
    message:''
  }
  locations = [
    // { lat: 24.753320, lng:46.713820 },
    // { lat: 21.527830, lng: 39.185490 },
    // { lat:24.488760, lng: 39.589690 }
   ];
   location =[
    //  {lat:24.753321,lng:46.713821}
   ]
  constructor(private pageService:PagesService, private zone: NgZone) { 
  
  }

  ngOnInit() {
    this.pageService.getContacts(this.data).subscribe((resp: any) => {
      this.contacts= resp.data.data;
      this.all_offices =this.contacts.filter(contact=>contact.type=='offices')
      this.all_bankers =this.contacts.filter(contact=>contact.type=='bankers')
      console.log("off",this.all_offices)

      this.loadMap(); 
     this.offices = _.groupBy(this.all_offices,item => item.country);
     this.bankers = _.groupBy(this.all_bankers,item => item.country);
        
    }, (err: any) => {
      console.log(err);
    });
 
 
   
  }
  
  changeType(type){
    this.type=type;
    console.log("type",type==='offices');
    this.loadMap();
    
   
  }

  loadMap(){
    if(this.type==="offices"){
      console.log("enter",this.all_offices)
      const loader = new Loader({
        apiKey: "AIzaSyBEI5t9UiuxZScHS1_vx0zwQsoR3dRQLLU",
        version: "weekly",
      });
     
  
      loader.load().then(() => {
        let map = new google.maps.Map(
          document.getElementById("map") as HTMLElement,
          {
            center: {
              lat:24.753321,
              lng:46.713821,
            },
            zoom: 8,
          }
        );
        var infowindow = new google.maps.InfoWindow();    
        var bounds = new google.maps.LatLngBounds();
        for (let i = 0; i < this.all_offices.length; i++) { 
          let marker = new google.maps.Marker({
            position: {lat:parseFloat(this.all_offices[i].latitude),lng: parseFloat(this.all_offices[i].longitude)},
           clickable:true,
            map: map
          });  
          marker.addListener('click', () => {
            this.zone.run(() => { 
              let m = this.all_offices[i];
              var link = "http://www.google.com/maps/place/"+m.latitude+","+m.longitude;
              // $location.path(link);
              window.open(link);
          });
        });
          map.setZoom(20);
          if(this.all_offices[i].latitude && this.all_offices[i].longitude )
          bounds.extend({lat:parseFloat(this.all_offices[i].latitude),lng: parseFloat(this.all_offices[i].longitude)});
          var geocoder = new google.maps.Geocoder();

          // google.maps.event.addListener(map, 'click', function(event) {
          //   console.log("evnt",event.latLng  )
          //   geocoder.geocode({
          //     latLng: event.latLng
          //   }, function(results, status) {
          //     if (status == google.maps.GeocoderStatus.OK) {
          //       if (results[0]) {
          //         alert(results[0].formatted_address);
          //       }
          //     }
          //   });
          // });
          // google.maps.event.addListener(marker, 'click', (function(marker, i) {
          //   return function() {
          //     infowindow.setContent("this.locations[i][0]");
          //     infowindow.open(map, marker);
          //   }
          // })(marker, i));
        }
        map.fitBounds(bounds);
        //(optional) restore the zoom level after the map is done scaling
        var listener = google.maps.event.addListener(map, "idle", function () {
            map.setZoom(3);
            google.maps.event.removeListener(listener);
        });
        // const marker = new google.maps.Marker({
        //   position: {
        //     lat: 24.753320,
        //     lng: 46.713820,
        //   },
        //   map: map,
        // });
      });
   

    }
    if(this.type==="bankers"){
      console.log("this",this.all_bankers)
      const loader1 = new Loader({
        apiKey: "AIzaSyBEI5t9UiuxZScHS1_vx0zwQsoR3dRQLLU",
        version: "weekly",
      });
      loader1.load().then(() => {
        let map = new google.maps.Map(
          document.getElementById("bank-map") as HTMLElement,
          {
            center: {
              lat:24.753321,
              lng:46.713821,
            },
            zoom: 8,
          }
        );
        var infowindow = new google.maps.InfoWindow();    
        var bounds = new google.maps.LatLngBounds();
        for (let i = 0; i < this.all_bankers.length; i++) {
          console.log("get",this.all_bankers[i])  
          let marker = new google.maps.Marker({
                        position: {lat:parseFloat(this.all_bankers[i].latitude),lng:parseFloat(this.all_bankers[i].longitude)},

            // position: new google.maps.LatLng(this.all_bankers[i].latitude, this.all_bankers[i].longitude),
            map: map
          });
          if(this.all_bankers[i].latitude && this.all_bankers[i].longitude )
            bounds.extend({lat:parseFloat(this.all_bankers[i].latitude),lng:parseFloat(this.all_bankers[i].longitude)});

          // bounds.extend(new google.maps.LatLng(this.all_bankers[i].latitude, this.all_bankers[i].longitude));
          // google.maps.event.addListener(marker, 'click', (function(marker, i) {
          //   return function() {
          //     infowindow.setContent("this.locations[i][0]");
          //     infowindow.open(map, marker);
          //   }
          // })(marker, i));
        }
        map.fitBounds(bounds);
        //(optional) restore the zoom level after the map is done scaling
        var listener = google.maps.event.addListener(map, "idle", function () {
            map.setZoom(3);
            google.maps.event.removeListener(listener);
        });
        // const marker = new google.maps.Marker({
        //   position: {
        //     lat: 24.753320,
        //     lng: 46.713820,
        //   },
        //   map: map,
        // });
      });
    }
    
  }
  onSubmit(form:NgForm){
    console.log("form.valid",form.valid,this.data)
 
    this.pageService.postEnquiry(this.data).subscribe((resp: any) => {
      console.log("data",resp)
      if(resp.success) {
        var dialog = bootbox.dialog({
          message: 'Enquiry submitted successfully!!',
          closeButton: true
        });
        form.resetForm();
          
      }
 
    }, (err: any) => {
      console.log(err);
    });

  }

}
