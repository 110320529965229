import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/core/services/api/api.service';
import { PagesService } from 'src/app/core/services/pages/pages.service';

@Component({
  selector: 'app-sample-projects',
  templateUrl: './sample-projects.component.html',
  styleUrls: ['./sample-projects.component.css']
})
export class SampleProjectsComponent implements OnInit {

  projects:any=[];
  constructor(private pageService:PagesService,private apiService:ApiService) { }

  ngOnInit() {
    this.pageService.getProjects().subscribe((resp: any) => {
      this.projects = resp.data.data;      
    }, (err: any) => {
      console.log(err);
    });
  }
}
