import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/core/services/api/api.service';
import { PagesService } from 'src/app/core/services/pages/pages.service';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})
export class DetailsComponent implements OnInit {
  id:any;
  news:any;

  constructor(private _activatedRoute:ActivatedRoute,public sanitizer: DomSanitizer, private pageService:PagesService, private apiService:ApiService) { }

  ngOnInit() {
    this._activatedRoute.params.subscribe((params) => {
      console.log(params);
      this.id = params.id;
      this.pageService.getNewsDetails(this.id).subscribe((resp: any) => {
        this.news = resp.data;      
      }, (err: any) => {
        console.log(err);
      });
    });
  }

}
