import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ApiService } from 'src/app/core/services/api/api.service';
import { ProductsService } from 'src/app/core/services/products/products.service';

@Component({
  selector: 'app-medical-devices',
  templateUrl: './medical-devices.component.html',
  styleUrls: ['./medical-devices.component.css']
})
export class MedicalDevicesComponent implements OnInit {
 categories:any=[];
 products:any=[];
 device_list:any=[];
 sub_cat_list:any=[];
 sub_categories:any=[];
 id:any;
 selected_sub;
  constructor(private productService:ProductsService,public apiService:ApiService,private _activatedRoute:ActivatedRoute) { }

  ngOnInit() {
    this._activatedRoute.params.subscribe((params) => {
      console.log(params);
    this.productService.getCategories().subscribe((resp: any) => {
      
      this.categories = resp.data.data;    
      if(params.id) {
        this.id =params.id ;
        const index =  this.categories.findIndex(e => e.id === this.id)
        this.categories.unshift( this.categories.splice(index, 1)[0])
      }
      else this.id =this.categories[0].id;
   
      this.productService.getSubCategories().subscribe((resp: any) => {
        this.sub_categories = resp.data.data; 
        this.sub_cat_list = this.sub_categories.filter(sub=>{
          return sub.category===this.id
        }) 
        this.selected_sub = this.sub_cat_list[0]; 
        console.log("selected",this.selected_sub)
        this.productService.getMedicalDevices().subscribe((resp: any) => {
          this.products = resp.data.data; 
         this.getList();       
        }, (err: any) => {
          console.log(err);
        });  
        console.log("cat",this.sub_cat_list) 
      }, (err: any) => {
        console.log(err);
      });  
    }, (err: any) => {
      console.log(err);
    });
  });
  }
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    margin:0,
    dots: false,
    autoWidth: true,
    navSpeed: 700,
    navText: [
      '<i class="fa fa-angle-left" aria-hidden="true"></i>',
      '<i class="fa fa-angle-right" aria-hidden="true"></i>',
    ],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 3,
      },
      740: {
        items: 4,
        loop:true

      },
      940: {
        items: 4  ,
        // items: this.couple_plans.length<3?this.couple_plans.length:3,
        loop:true
      },
    },
    nav: true,
  };
  changeType(sub){
    this.selected_sub = sub; 
    (this.sub_cat_list.length) ? (   this.device_list = this.products.filter(product=>{
      return product.category===this.id && product.subcategory ===this.selected_sub.id
    })  )  :    ( this.device_list = this.products.filter(product=>{
      return product.category===this.id
    })  )
    console.log("list",this.device_list);

    if(this.device_list.length){
      if ('position' in this.device_list[0]) {
        this.device_list.sort((a, b) => a.position - b.position);
      }
    }
  }
  getDevices(cat){
    this.id=cat.id  
    this.sub_cat_list = this.sub_categories.filter((sub) => {
      return sub.category === this.id;
    }); 
    this.selected_sub = this.sub_cat_list[0]; 

    console.log("selected",this.selected_sub);
    this.getList();

    // !this.device_list.length && this.device_list.push({category:cat.id});
  }
  getList(){

    (this.sub_cat_list.length) ? (   this.device_list = this.products.filter(product=>{
      return product.category===this.id && product.subcategory ===this.sub_cat_list[0].id
    })  )  :    ( this.device_list = this.products.filter(product=>{
      return product.category===this.id
    })  )
    console.log("list",this.device_list);

    if(this.device_list.length){
      if ('position' in this.device_list[0]) {
        this.device_list.sort((a, b) => a.position - b.position);
      }
    }
  }

}
