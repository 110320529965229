import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { PagesService } from 'src/app/core/services/pages/pages.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  news:any=[];
  cms:any;
  constructor(public router:Router, private pageService:PagesService,public sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.pageService.getNews().subscribe((resp: any) => {
      this.news = resp.data.data; 
    }, (err: any) => {
      console.log(err);
    })
    this.pageService.getCms('settings').subscribe((resp: any) => {
      this.cms = resp.data; 
      console.log("cms",this.cms)
    }, (err: any) => {
      console.log(err);
    })
  }
  goOwnership(){
    this.router.navigateByUrl('/ownership')
  }
  goDept(){
    this.router.navigateByUrl('/service-departments')
  }
  goStrategic(){
    this.router.navigateByUrl('/strategic-direction#first-block')

  }
  getContact(){
    console.log("contact");
    this.router.navigateByUrl('/contact-us')
  }
  getCareers(){
    this.router.navigateByUrl('/careers')

  }
  goAbout(){
    this.router.navigateByUrl('/partners')
  }
  goPrivacy(){
    this.router.navigateByUrl('/privacy')
  }
  

}
