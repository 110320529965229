import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ApiService } from 'src/app/core/services/api/api.service';
import { PagesService } from 'src/app/core/services/pages/pages.service';

@Component({
  selector: 'app-ownership',
  templateUrl: './ownership.component.html',
  styleUrls: ['./ownership.component.css']
})
export class OwnershipComponent implements OnInit {
  ownership:any;
  leadership:any;
  outstanding:any;

  constructor(private pageService:PagesService,private apiService:ApiService,public sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.pageService.getCms("ownership").subscribe(
      (resp: any) => {
        this.ownership = resp.data;
      },
      (err: any) => {
        console.log(err);
      }
    );
    this.pageService.getCms("leadership").subscribe(
      (resp: any) => {
        this.leadership = resp.data;
      },
      (err: any) => {
        console.log(err);
      }
    );
    this.pageService.getCms("outstanding").subscribe(
      (resp: any) => {
        this.outstanding = resp.data;
      },
      (err: any) => {
        console.log(err);
      }
    );
  }

}
