import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Router } from "@angular/router";
import { AuthService } from "../services/auth/auth.service";


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private authService : AuthService,private router : Router){}

    intercept(req: HttpRequest<any>, next: HttpHandler) {        
        
            return next.handle( this.addAuthenticationToken(req)).pipe(
                tap(
                    event => { },
                    err => {
                        console.log("error",err.status)
                        if (err.error.auth == false) {
                            this.router.navigateByUrl('/');
                        }
                        if(err.status===401){                           
                            this.authService.deleteToken();
                            this.router.navigateByUrl('/');
                        }
                    })
            );
        }
        addAuthenticationToken(request) {
            
            const accessToken = this.authService.getToken();
            if (!accessToken ) {                
                return request;
                }
            return request.clone({
                setHeaders: {
                    Authorization:"Bearer " +accessToken
                }
            });
        }
    }