import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/core/services/api/api.service';
import { PagesService } from 'src/app/core/services/pages/pages.service';

@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.css']
})
export class CareersComponent implements OnInit {
  jobs:any=[];
  all_jobs:any=[];
  job_category:any=[];
  career;
  filters={
    keyWord:'',
    category:'',
    location:'',
    jobType:''

  };
  constructor(private pageService:PagesService,private apiService:ApiService ,public sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.getJobs();

    this.pageService.getCms("career").subscribe(
      (resp: any) => {
        this.career = resp.data;
      },
      (err: any) => {
        console.log(err);
      }
    );
    this.pageService.getJobCategory().subscribe((resp: any) => {
      this.job_category= resp.data.data;      
    }, (err: any) => {
      console.log(err);
    });
  }
  getJobs(){

    this.pageService.getJobs().subscribe((resp: any) => {
      this.jobs = resp.data.data;   
      this.all_jobs= resp.data.data;   
    }, (err: any) => {
      console.log(err);
    });
  }
  selectCat(event){
    this.filters.category = event;
  }
  selectType(event){
    console.log("event",event)
    this.filters.jobType = event;
  }
  search(){  
    var filter ={}
    for (var key in this.filters) {
      if (this.filters[key]!== "") {
        filter[key]=this.filters[key];
        // delete filter[key];
      }
    } 
    console.log("list",this.filters,filter)  
   this.jobs= this.all_jobs.filter(function(item) {
      for (var key in filter) {
        console.log("ke",item[key],filter[key],item[key]==filter[key])
        if(Array.isArray(item[key])){
          if(!item[key].includes(filter[key])) return false;
        }else{
          if (item[key] === undefined || item[key].toLowerCase() != filter[key].toLowerCase())
          return false;
        }
        
      }
      return true;
    });   

  }
  clear(form){  
   form.reset({
    keyWord:'',
      category:'',
      location:'',
      jobType:''
  });
this.jobs = this.all_jobs;
  }

  

}
