import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ApiService } from 'src/app/core/services/api/api.service';
import { PagesService } from 'src/app/core/services/pages/pages.service';

@Component({
  selector: 'app-higher-management-structure',
  templateUrl: './higher-management-structure.component.html',
  styleUrls: ['./higher-management-structure.component.css']
})
export class HigherManagementStructureComponent implements OnInit {
  team = [];
  constructor(private pageService:PagesService,private apiService:ApiService,public sanitizer: DomSanitizer) { }
  valuable;
  management;
  ngOnInit() {
    this.pageService.getTeam().subscribe((resp: any) => {
      this.team = resp.data.data;      
    }, (err: any) => {
      console.log(err);
    });
    this.pageService.getCms("valuable-team").subscribe(
      (resp: any) => {
        this.valuable = resp.data;
      },
      (err: any) => {
        console.log(err);
      }
    );
    this.pageService.getCms("management").subscribe(
      (resp: any) => {
        this.management = resp.data;
      },
      (err: any) => {
        console.log(err);
      }
    );
  
  }
  teamOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    margin:0,
    dots: false,
    autoWidth: true,
    navSpeed: 700,
    // stagePadding: 150,
    navText: [
      '<i class="fa fa-angle-left" aria-hidden="true"></i>',
      '<i class="fa fa-angle-right" aria-hidden="true"></i>',
    ],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 3,
      },
      740: {
        items: 3,
        loop:true

      },
      940: {
        items: 3,
        loop:true
      },
    
    },
    nav: true,
  };

}
