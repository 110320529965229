import { Component, OnInit } from "@angular/core";
import { DatePipe } from "@angular/common";
import { OwlOptions } from "ngx-owl-carousel-o";
import { PaginationInstance } from "ngx-pagination";
import { ApiService } from "src/app/core/services/api/api.service";
import { Router } from "@angular/router";
import { PagesService } from "src/app/core/services/pages/pages.service";
import { ProductsService } from "src/app/core/services/products/products.service";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent implements OnInit {
  banners: any = [];
  products: any = [];
  categories: any = [];
  device_list: any = [];
  team: any = [];
  partners = [];
  message;
  cms_news;
  cms_event;
  cms_partner;
  cms_team;
  cms_dept;
  events = [];
  service_department:any;
  news = [];
  selected_category;
  selected_news: any;
  ngOnInit(): void {
    this.pageService.getBanner().subscribe(
      (resp: any) => {
        this.banners = resp.data.data;
      },
      (err: any) => {
        console.log(err);
      }
    );
    this.pageService.getPartners().subscribe(
      (resp: any) => {
        this.partners = resp.data.data;
      },
      (err: any) => {
        console.log(err);
      }
    );
    this.pageService.getTeam().subscribe(
      (resp: any) => {
        this.team = resp.data.data;
      },
      (err: any) => {
        console.log(err);
      }
    );
    this.pageService.getCms("ceo-message").subscribe(
      (resp: any) => {
        this.message = resp.data;
      },
      (err: any) => {
        console.log(err);
      }
    );
    this.pageService.getCms("service-dept-home").subscribe(
      (resp: any) => {
        this.service_department = resp.data;
      },
      (err: any) => {
        console.log(err);
      }
    );
    this.pageService.getCms("event").subscribe(
      (resp: any) => {
        this.cms_event = resp.data;
      },
      (err: any) => {
        console.log(err);
      }
    );
    this.pageService.getCms("news").subscribe(
      (resp: any) => {
        this.cms_news = resp.data;
      },
      (err: any) => {
        console.log(err);
      }
    );
    this.pageService.getCms("partner").subscribe(
      (resp: any) => {
        this.cms_partner = resp.data;
      },
      (err: any) => {
        console.log(err);
      }
    );
    this.pageService.getCms("team").subscribe(
      (resp: any) => {
        this.cms_team = resp.data;
      },
      (err: any) => {
        console.log(err);
      }
    );
  
    this.pageService.getNews().subscribe(
      (resp: any) => {
        this.news = resp.data.data;
        this.selected_news = this.news[0];
      },
      (err: any) => {
        console.log(err);
      }
    );
    this.pageService.getevents().subscribe(
      (resp: any) => {
        this.events = resp.data.data;
      },
      (err: any) => {
        console.log(err);
      }
    );
    this.productService.getCategories().subscribe(
      (resp: any) => {
        this.categories = resp.data.data;
        this.productService.getMedicalDevices().subscribe(
          (resp: any) => {
            this.products = resp.data.data;
            this.selected_category = this.categories[0];

            // this.device_list = this.products.filter((product) => {
            //   console.log("product", product, this.categories);
            //   return product.category === this.categories[0].id;
            // });
            console.log("device", this.device_list);
          },
          (err: any) => {
            console.log(err);
          }
        );
      },
      (err: any) => {
        console.log(err);
      }
    );
  }
  constructor(
    public router: Router,
    private pageService: PagesService,
    public apiService: ApiService,
    public productService: ProductsService,
    public sanitizer: DomSanitizer
  ) {}
  bannerOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    margin: 0,
    dots: true,
    autoWidth: true,
    navSpeed: 700,
    navText: [
      '<i class="fa fa-angle-left" aria-hidden="true"></i>',
      '<i class="fa fa-angle-right" aria-hidden="true"></i>',
    ],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      740: {
        items: 1,
        loop: true,
      },
      940: {
        items: 1,
        // items: this.couple_plans.length<3?this.couple_plans.length:3,
        loop: true,
      },
    },
    nav: false,
  };
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    margin: 0,
    dots: false,
    autoWidth: true,
    navSpeed: 700,
    navText: [
      '<i class="fa fa-angle-left" aria-hidden="true"></i>',
      '<i class="fa fa-angle-right" aria-hidden="true"></i>',
    ],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 3,
      },
      740: {
        items: 4,
        loop: true,
      },
      940: {
        items: 4,
        // items: this.couple_plans.length<3?this.couple_plans.length:3,
        loop: true,
      },
    },
    nav: true,
  };
  partnerOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    margin: 0,
    dots: false,
    autoWidth: true,
    navSpeed: 700,
    navText: [
      '<i class="fa fa-angle-left" aria-hidden="true"></i>',
      '<i class="fa fa-angle-right" aria-hidden="true"></i>',
    ],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      740: {
        items: 6,
        loop: true,
      },
      940: {
        items: 6,
        // items: this.couple_plans.length<3?this.couple_plans.length:3,
        loop: true,
      },
    },
    nav: true,
  };
  eventOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    margin: 0,
    dots: false,
    autoWidth: true,
    navSpeed: 700,
    navText: [
      '<i class="fa fa-angle-left" aria-hidden="true"></i>',
      '<i class="fa fa-angle-right" aria-hidden="true"></i>',
    ],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      740: {
        // items: 3,
        // loop:true
      },
      940: {
        // items: 3,
        // items: this.couple_plans.length<3?this.couple_plans.length:3,
        loop: true,
      },
    },
    nav: true,
  };
  teamOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    margin: 0,
    dots: false,
    dotsEach: 7,
    autoWidth: true,
    navSpeed: 700,
    // stagePadding: 150,
    navText: [
      '<i class="fa fa-angle-left" aria-hidden="true"></i>',
      '<i class="fa fa-angle-right" aria-hidden="true"></i>',
    ],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 3,
      },
      740: {
        items: 3,
        loop: true,
      },
      940: {
        items: 3,
        loop: true,
      },
    },
    nav: true,
  };
  getDevices(cat) {
    this.selected_category = cat;
    // this.device_list = this.products.filter((product) => {
    //   return product.category === cat.id;
    // });
    // !this.device_list.length && this.device_list.push({ category: cat.id });
  }

  changeSelectedNews(id) {
    console.log("id", id);
    this.selected_news = this.news.filter((selected) => {
      return selected.id == id;
    })[0];
  }
}
