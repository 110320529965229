import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { UtilsService } from './../utils/utils.service';
import { Observable } from 'rxjs';

@Injectable()
export class ApiService {

  private readonly url: string = environment.apiBaseUrl;

  constructor(private http: HttpClient, public utils: UtilsService) { }

  // create get method
  get(endpoint: string, params?: any, reqOpts?: any): any {
    if (!reqOpts) {
      reqOpts = {
        params: new HttpParams()
      };
    }
    // Support easy query params for GET requests
    if (params) {
      reqOpts.params = new HttpParams();
      for (const k in params) {
        if (params.hasOwnProperty(k)) {
          reqOpts.params = reqOpts.params.set(k, params[k]);
        }
      }
    }
    return this.http.get(this.url + '/' + endpoint, reqOpts);
  }

  post(endpoint: string, body: any, reqOpts?: any): any {
    // Using the POST method
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    body = this.utils.cleanParams(body);
    return this.http.post<any>(this.url + '/' + endpoint, body, { responseType: 'json', headers, withCredentials: false });
  }

  put(endpoint: string, body: any, reqOpts?: any): any {
    return this.http.put(this.url + '/' + endpoint, body, reqOpts);
  }

  delete(endpoint: string, reqOpts?: any): any {
    return this.http.delete(this.url + '/' + endpoint, reqOpts);
  }

  patch(endpoint: string, body: any, reqOpts?: any): any {
    return this.http.patch(this.url + '/' + endpoint, body, reqOpts);
  }

  postFile(endpoint: string, fileToUpload: File): any {
    // Using the POST method
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    const headers = new HttpHeaders();
    return this.http.post<any>(this.url + '/' + endpoint, formData, { responseType: 'json', headers, withCredentials: false });
  }

}
