import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/core/services/api/api.service';
import { PagesService } from 'src/app/core/services/pages/pages.service';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css']
})
export class EventsComponent implements OnInit {

  events:any=[];
  constructor(private pageService:PagesService,private apiService:ApiService) { }

  ngOnInit() {
    this.pageService.getevents().subscribe((resp: any) => {
      this.events = resp.data.data; 
    }, (err: any) => {
      console.log(err);
    })
  }
}
